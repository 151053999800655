import i18n from '@/lang/lang';

export const filedRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.filedRules.required'),
        counter: (value) => value && value.length >= 3 || i18n.t('rule.filedRules.counter'),
    }
};
export const tagRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.tagRules.required'),
        counter: (value) => value && value.length <= 10 || i18n.t('rule.tagRules.required'),
    }
};
export const eligibleRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.eligibleRules.required'),
        counter: (value) => value && value.length < 1 || i18n.t('rule.eligibleRules.required'),
    }
};
export const declarationRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.declarationRules.required'),
        counter: (value) => value && value.length > 5 || i18n.t('rule.declarationRules.counter'),
    }
};
export const SSNRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.SSNRules.required'),
        counter: (value) => value && value.length > 10 || i18n.t('rule.SSNRules.counter'),
        check: (value) => {
            const pattern = /^\d+$/;
            return pattern.test(value) || i18n.t('rule.SSNRules.check');
        },
    }
};
export const passportRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.passportRules.required'),
        counter: (value) => value && value.length > 8 || i18n.t('rule.passportRules.counter'),
        check: (value) => {
            const pattern = /^\d+$/;
            return pattern.test(value) || i18n.t('rule.passportRules.check');
        },
    }
};
export const idRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.idRules.required'),
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || i18n.t('rule.idRules.email');
        },
    }
};
export const emailRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.emailRules.required'),
        // required: (value) => !!value || i18n.t('login.rule.emailReq'),
        counter: (value) => value && value.length <= 100 || i18n.t('rule.emailRules.counter'),
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || i18n.t('rule.emailRules.email');
        },
    }
};
export const loginIdRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.loginIdRules.required'),
        counter: (value) => value && value.length <= 100 || i18n.t('rule.loginIdRules.counter'),
        email: (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || i18n.t('rule.loginIdRules.email');
        },
    }
};

export const passwordRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.passwordRules.required'),
        counter: (value) =>
            value && (value.length >= 8 && value.length <= 20) ||
            i18n.t('rule.passwordRules.counter'),
        check: (value) => {
            let num = value.search(/[0-9]/g);
            let eng = value.search(/[a-z]/ig);
            let spe = value.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
            const pattern = (num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0);
            // const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
            console.log(pattern);
            return !pattern || i18n.t('rule.passwordRules.check');
        }
    }
};
export const cellRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.cellRules.required'),
        counter: (value) => value && value.length > 8 && value.length < 12 || i18n.t('rule.cellRules.common'),
        min: (value) => value && value.length > 8 || i18n.t('rule.cellRules.common'),
        max: (value) => value && value.length < 12 || i18n.t('rule.cellRules.common'),
        check: (value) => {
            const pattern = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/g;
            return pattern.test(value) || i18n.t('rule.cellRules.common');
        },
    }
};
export const nameRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.nameRules.required'),
        counter: (value) => value && value.length >= 2 && value.length <= 10 || i18n.t('rule.nameRules.counter'),
        min: (value) => value && value.length > 0 || i18n.t('rule.nameRules.min'),
    }
};
export const nicknameRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.nicknameRules.required'),
        counter: (value) => value && value.length >= 2 && value.length <= 10 || i18n.t('rule.nicknameRules.counter')
    }
};
export const birthdayRules = () => {
    return {
        required: (value) => !!value || i18n.t('rule.birthdayRules.required'),
        check: (value) => {
            const pattern =
                /^(19[0-9][0-9]|20\d{2}|2100)(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g;
            return pattern.test(value) || i18n.t('rule.birthdayRules.check');
        },
    }
};
export const birthtimeRules = () => {
    return {
        counter: (value) => !value || (!!value && value.length == 4) || i18n.t('rule.birthtimeRules.counter'),
        check: (value) => {
            const pattern = /^(0[0-9]|1[0-9]|2[0-3])(0[0-9]|[1-5][0-9])$/g;
            return pattern.test(value) || i18n.t('rule.birthtimeRules.check');
        },
    }
};
export const daysOfWeek = () => {
    let reData = ["일", "월", "화", "수", "목", "금", "토"];
    return reData;
};
export const monthName = () => {
    let reData = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
    ];
    return reData;
};
export const s3Config = () => {
    let reData = {
        S3_BUCKET: 'saju-bucket',
        S3_REGION: 'ap-northeast-2',
        S3_POOLID: 'ap-northeast-2:205c9c55-719e-44aa-8f2e-f368ad2fc070'
    }
    return reData;
};
export const predict_s3Config = () => {
    let reData = {
        S3_BUCKET: 'rentcar-carphoto',
        S3_REGION: 'ap-northeast-2',
        S3_POOLID: 'ap-northeast-2:7a01d393-8bc9-486e-8f0a-a71d7722776b',
        S3_UPLOAD_ROOT: 'kimCare'
    }
    return reData;
};
export const ccCare_s3Config = () => {
    let reData = {
        S3_BUCKET: 'cccare-file',
        S3_REGION: 'us-west-1',
        S3_POOLID: 'us-west-1:3dd725be-4d3a-4b17-b40b-b6486a3364bc',
        S3_UPLOAD_ROOT: 'ccCare'
    }
    return reData;
};
export const birthTime = () => {
    let reData = [
        { value: "", text: "모름" },
        { value: "2330", text: "자 (23:30 ~ 01:29)" },
        { value: "0130", text: "축 (01:30 ~ 03:29)" },
        { value: "0330", text: "인 (03:30 ~ 05:29)" },
        { value: "0530", text: "묘 (05:30 ~ 07:29)" },
        { value: "0730", text: "진 (07:30 ~ 09:29)" },
        { value: "0930", text: "사 (09:30 ~ 11:29)" },
        { value: "1130", text: "오 (11:30 ~ 13:29)" },
        { value: "1330", text: "미 (13:30 ~ 15:29)" },
        { value: "1530", text: "신 (15:30 ~ 17:29)" },
        { value: "1730", text: "유 (17:30 ~ 19:29)" },
        { value: "1930", text: "술 (19:30 ~ 21:29)" },
        { value: "2130", text: "해 (21:30 ~ 23:29)" },
    ];
    return reData;
};
export const requiredRule = value => {
    return !!value || "Required.";
};
export const ko_requiredRule = value => {
    return !!value || "필수입력입니다.";
};